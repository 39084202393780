import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IClientIssueHolder } from '@shared-global/interfaces/clients-issues-holders/interfaces/client-issue-holder.interface';
import { IFindClientsIssuesHoldersByCriteriaRequest } from '@shared-global/interfaces/clients-issues-holders/interfaces/find-clients-issues-holders-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export type ClientIssueHoldersRequests = IFindClientsIssuesHoldersByCriteriaRequest &
  QueryParamsInterface;

@Injectable({
  providedIn: 'root',
})
export class ClientIssueHoldersService extends AbstractCrudService<
  IClientIssueHolder,
  ClientIssueHoldersRequests
> {
  protected override feature = 'clients-issues-holders';
  protected override endPoint = `/clients-issues-holders/`;
}
